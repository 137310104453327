import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'
import {requestSubmit} from '@github-ui/form-utils'
import {updateSearchParams} from '@github-ui/history'

on('remote-input-error', '#js-codespaces-repository-select', () => {
  const warning = document.querySelector<HTMLElement>('#js-codespaces-unable-load-repositories-warning')!
  warning.hidden = false
})

function updateNewCodespaceUrl(form: FormData): void {
  const url = new URL(document.location.href, window.location.origin)
  const urlParams = new URLSearchParams(url.search)
  // Certain form name/values we don't want to save as these are very ENV specific.
  // They can cause problems in different environments (e.g. review lab, local, production, etc...)
  // Add those keys to this list as needed.
  const paramsKeysToReject = ['vscs_target']

  for (const [key, value] of form.entries()) {
    if (paramsKeysToReject.includes(key) || !value) {
      urlParams.delete(key)
      continue
    }

    urlParams.set(key, value as string)
  }

  updateSearchParams(urlParams)
}

remoteForm('.js-new-codespace-form', async function (form, wants) {
  const container = form.closest<HTMLElement>('[data-replace-remote-form-target]')!
  const submitButton = container.querySelector('.js-new-codespace-submit-button')

  if (submitButton instanceof HTMLInputElement) {
    submitButton.disabled = true
  }

  form.classList.remove('is-error')
  form.classList.add('is-loading')

  try {
    if (submitButton) {
      submitButton.setAttribute('disabled', 'true')
    }

    const response = await wants.html()
    if (response.status !== 200) {
      resetNewCodespacePageWithResponseError()
    }
    replaceAndRefocus(container, response.html)

    if (container.getAttribute('data-allow-update-url') === 'true') {
      const newFormData = new FormData(document.querySelector('form.js-new-codespace-form') as HTMLFormElement)
      updateNewCodespaceUrl(newFormData)
    }
  } catch (e) {
    resetNewCodespacePageWithResponseError()
    throw e // We still want this to wind up in telemetry
  }
})

export function replaceAndRefocus(target: HTMLElement, partial: DocumentFragment) {
  const partialContent = partial.querySelector('*')

  const focusedElement = target.ownerDocument.activeElement

  let elementToRefocus
  if (focusedElement instanceof HTMLElement) {
    elementToRefocus = partialContent?.querySelector(generateCssSelectorForElement(focusedElement))
  }

  target.replaceWith(partial)

  if (elementToRefocus instanceof HTMLElement) {
    elementToRefocus.focus()
  }
}

function generateCssSelectorForElement(element: HTMLElement) {
  const tagName = element.tagName.toLowerCase()
  const classNames = element.hasAttribute('class') ? `.${element.className.split(' ').join('.')}` : ''
  const id = element.hasAttribute('id') ? `#${element.id}` : ''
  const name = element.hasAttribute('name') ? `[name="${element.getAttribute('name')}"]` : ''

  return `${tagName}${id}${classNames}${name}`
}

function resetNewCodespacePageWithResponseError() {
  const url = new URL(document.location.href, window.location.origin)
  const urlParams = new URLSearchParams(url.search)
  urlParams.set('response_error', 'true')
  window.location.replace(`${window.location.pathname}?${urlParams.toString()}`)
}

type LoadingState = null | 'provisioning' | 'provisioned' | 'stuck' | 'failed'

let loadingState: LoadingState = null

function advanceLoadingState(state: LoadingState) {
  loadingState = state

  if (state !== null) {
    const loadingSteps = document.querySelector<HTMLDivElement>('.js-codespace-loading-steps')!
    loadingSteps.setAttribute('data-current-state', loadingState as string)
  }
}

observe('.js-codespace-loading-steps', {
  constructor: HTMLElement,
  add: el => {
    const currentState = el.getAttribute('data-current-state') as LoadingState
    if (currentState) advanceLoadingState(currentState)
  },
})

observe('.js-codespace-advance-state', {
  constructor: HTMLElement,
  add: el => {
    const targetState = el.getAttribute('data-state') as LoadingState
    if (targetState) advanceLoadingState(targetState)
  },
})

observe('.js-auto-submit-form', {
  constructor: HTMLFormElement,
  initialize: requestSubmit,
})

observe('.js-workbench-form-container', {
  constructor: HTMLElement,
  add: () => {
    const form = document.querySelector('.js-workbench-form-container form') as HTMLFormElement
    requestSubmit(form)
  },
})
